@import "_globals";

/*=============================
=           Address           =
=============================*/

.address__image {
    width: 106px;

    > img {
        width: 100%;
        height: auto;

        .white & {
            border: 1px solid #C1D7E9;
        }

        .blue & {
            border: 2px solid #FFF;
        }
    }
}

.address__content {
    > * + * {
        margin-top: 6px;
    }
}

.address__title {
    margin-bottom: 0;
}

.address__description {
    p {
        margin-bottom: 0;

        + * {
            margin-top: 13px;
        }
    }
}

.seminar_address_description {
    margin-top: 6px;
}