/*=============================
=           Address           =
=============================*/
.address__image {
  width: 106px;
}
.address__image > img {
  width: 100%;
  height: auto;
}
.white .address__image > img {
  border: 1px solid #C1D7E9;
}
.blue .address__image > img {
  border: 2px solid #FFF;
}
.address__content > * + * {
  margin-top: 6px;
}
.address__title {
  margin-bottom: 0;
}
.address__description p {
  margin-bottom: 0;
}
.address__description p + * {
  margin-top: 13px;
}
.seminar_address_description {
  margin-top: 6px;
}
